export const proportions = [
  0.07575508, 0.06619601, 0.05835029, 0.05183037, 0.0463526,
  0.04170543, 0.0377285, 0.03429843, 0.03131904, 0.02871442,
  0.02642405, 0.02439916, 0.0226001, 0.0209944, 0.01955519,
  0.01826016, 0.0170906, 0.01603075, 0.01506725, 0.01418874,
  0.01338546, 0.01264904, 0.01197222, 0.01134872, 0.01077306,
  0.01024046, 0.0097467, 0.00928808, 0.00886135, 0.00846359,
  0.00809223, 0.00774497, 0.00741977, 0.0071148, 0.00682839,
  0.00655908, 0.00630551, 0.00606648, 0.0058409, 0.00562778,
  0.0054262, 0.00523534, 0.00505446, 0.00488287, 0.00471994,
  0.0045651, 0.00441782, 0.0042776, 0.00414401, 0.00401663,
  0.00389509, 0.00377902, 0.00366811, 0.00356204, 0.00346055,
  0.00336337, 0.00327026, 0.003181, 0.00309537, 0.00301318,
  0.00293424, 0.0028584, 0.00278548, 0.00271534, 0.00264783,
  0.00258284, 0.00252022, 0.00245988, 0.00240169, 0.00234556,
  0.00229139, 0.0022391, 0.00218858, 0.00213978, 0.00209259,
  0.00204697, 0.00200283, 0.00196012, 0.00191877, 0.00187873,
  0.00183994, 0.00180234, 0.0017659, 0.00173056, 0.00169628,
  0.00166301, 0.00163072, 0.00159937, 0.00156893, 0.00153935,
  0.00151061, 0.00148267, 0.00145551, 0.00142909, 0.00140339,
  0.00137839, 0.00135405, 0.00133035, 0.00130728, 0.00128481
]
